import { BLOG_LIST } from "../assests/BlogList.js";

import BlogIn from "./Blog_Ln.jsx";
export default function Blogina(){
    return     <section id="core-concepts">
    <ul>
    <h2 id="textt" style={{fontFamily:"Segoe U",color:"white",fontSize:"20px"}}>POPULAR POSTS</h2>
    <br />
{BLOG_LIST.map((conceptItem)=>
<BlogIn key={conceptItem.title} {...conceptItem}/>)}
    </ul>
</section>


}