import BLOG1 from '../images/blg8a-min.png';

import BLOG3 from '../images/blg3a.png';
import BLOG4 from '../images/blg4.png';
import BLOG5 from '../images/blg5a.png';
import BLOG6 from '../images/blg6a-min.png';
import BLOG7 from '../images/blg7a-min.png';
import BLOG9 from '../images/blg9a-min.png';
export const BLOG_LIST = [
  {
    image: BLOG9,
    title: '5 Common Mistakes Beginner Traders Make and How to Avoid Them',
   Link: '5-Common-Mistakes-Beginner-Traders-Make-and-How-to-Avoid-Them'
  }, 
  {
    image: BLOG1,
    title: 'Unprecedented Market Trends: What Investors Need to Know',
   Link: 'Understanding-Technical-Indicators'
  }, 

  {
    image: BLOG3,
    title: 'What Are Trading Signals and How Can They Help You',
   Link: "What-Are-Trading-Signals-and-How-Can-They-Help-You"
  },
 
  {
    image: BLOG4,
    title: 'The Basics of Stock Trading',
   Link: "The-Basics-of-Stock-Trading"
  },
  {
    image: BLOG5,
    title: 'Is Investing in Stocks Really Worth It in 2025',
   Link: "Is-Investing-in-Stocks-Really-Worth-It-in-2025"
  },
  
  {
    image: BLOG6,
    title: 'What Are Stocks and How Do Stocks Make Money',
   Link: "What-Are-Stocks-and-How-Do-Stocks-Make-Money"
  },
  
  {
    image: BLOG7,
    title: 'Starting Your Stock Trading Journey',
   Link: "Starting-Your-Stock-Trading-Journey"
  },
  ];
  
