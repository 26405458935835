import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import logo from './logotrade-min.png';

 const Footer =()=> {
  return (
    <>
      <section className="footer__section">
      <div className="footer__width">
      <div className="row">

        <div className='footer_col' >
        <img src={logo}  alt="logo"></img>

          <p>Empowering traders with actionable insights and strategies.
          Unlock your trading potential and grow with us.
 </p>

 <br />
        <i style={{color:"#fff",fontSize:"25px",opacity:"0.8"}} class='bx bxl-facebook'></i> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i style={{color:"#fff",fontSize:"25px",opacity:"0.8"}} class='bx bxl-instagram' ></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i style={{color:"#fff",fontSize:"25px",opacity:"0.8"}} class='bx bxl-twitter' ></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i  style={{color:"#fff",fontSize:"25px",opacity:"0.8"}} class='bx bxl-linkedin'></i>

        </div>
 
        <div className='footer_col'  >
          
        <h3 id='tet' >Quick Links</h3>

        <a id='tet' href='about' className='link__footer'>About Us</a>
        <a id='tet' href='faq' className='link__footer'>Faq</a>
        <a id='tet' href='Privacy' className='link__footer'>Privacy</a>
        <a id='tet' href='contact' className='link__footer'>Contact Us</a>
        
        </div>
 
        <div className='footer_col' >
        <h3>Contact</h3>
        <div className='contact_detil'>
        <i style={{display:"none"}} class="ri-phone-fill"></i>
        <span style={{display:"none"}}>
        <a href="tel:346 387 7187" >&nbsp;&nbsp;346 387 7187</a>
        </span>
      
        </div>
        <div className='contact_detil'>
        <i class="ri-mail-fill"></i>
        <span>  <a href="mailto: info@mytradesignal.com" >&nbsp;&nbsp; info@mytradesignal.com</a>
        </span>
        </div>
        <div style={{display:"none"}} className='contact_detil'>
        <i class="ri-map-pin-fill"></i>
        <span>24285 Katy Freeway, Suite 300 Katy TX 77494 </span>
        </div>     

        </div>
 
        </div>
        <hr style={{width:"100%",color:"#fff",height:"1px"}}/>
        <p style={{opacity:"0.6",textAlign:"center",color:"#ffff",fontFamily:"Manrope",fontWeight:"500",paddingBottom:"0px"}}>Copyright @ 2023 designed by My Trading Signal</p>

        </div>

      </section> 

    </>
  )
}
export default Footer;