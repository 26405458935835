import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/faq.css'
import {Helmet}from"react-helmet";
import t1 from '../images/tr2.png';
import ab1 from '../images/Group 7284-min.png';
import ab2 from '../images/Group 7285-min.png';
import ab3 from '../images/Frame 3572-min.png';
import bit from '../images/bitcoin_btc_0003-Recovered 1-min.png';
import signn from '../images/signn.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Faq =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>My Trade Signal FAQ - Your Trading Questions Answered</title>
      <meta name="description" content="Get answers to your trading questions with our comprehensive FAQ on My Trade Signal. Discover tips, insights, and solutions for better trading success"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
     <div className='main__t'>
     <div className='row_t'>

        <div className='t_col'>
          <img src={t1}></img>
        </div>
        <div className='t_col'>
          <h1>FAQ’s </h1>
          <p>Are you tired of your currency not going as far as it used to? Try tradingo and multiply your money! With their user-friendly interface and expert market analysis, you'll be earning more in no time.</p>
          <br />
          <button style={{color:"black"}}>Let’s Start A Trial <img style={{width:"10%"}} src={signn} alt="" /> </button>
        </div>
     </div>
     </div>
    </section>
  
<section>
  <div className='faq__sec'>
    <br />
    <h2>Frequently Asked Questions</h2>
    <br />
  <details>
  <summary data-aos="fade-right">What is MyTradeSignal?</summary>
  <div>
  <p>MyTradeSignal is a trading platform offering daily live trading alerts, market insights, and educational resources to help traders make informed decisions. </p>
  </div>
</details>
<br />
<details>
<summary data-aos="fade-right">How can I start my free trial? 
</summary>
  <div>
  <p>Click on "Start Your 7-Day Free Trial Now," enter your details, and get instant access to our platform. You can cancel anytime before the trial ends to avoid charges. </p>
  </div></details>
<br />
<details>
<summary data-aos="fade-right">Why do I need to provide credit card information for the free trial? 
</summary>  <div>
<p>Credit card information is required to activate the trial, but you won't be charged if you cancel before the trial period ends. </p>
</div>
</details>
<br />
<details>
<summary data-aos="fade-right">What do the daily trading alerts include?</summary>  <div>
<p>Alerts include specific trade setups with entry and exit points, daily market insights, and educational tips to help improve your trading skills. </p>
</div>

</details>
<br />
<details>
<summary data-aos="fade-right">How do the market insights help me? 
</summary>  
<div style={{fontWeight:"normal"}}>
 <p>Market insights provide a brief analysis of market conditions and trends, helping you understand the rationale behind each trading alert. </p>
</div>
</details>
  </div>
</section>


<br /><br />
<div className='div__9a'>
  <div className='div__9__row'>
    <div className='div__9a__col'>
      <img src={bit}></img>
    </div>
    <div className='div__9a__col'>
      <h2>Subscribe our news</h2>
    <p>
    Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</p>
    <input type="Name" style={{padding:"10px"}} value="Enter your email" />
    <button style={{color:"black",fontFamily:"Nunito",padding:"10px",border:"1px solid white"}}>Submit</button> 
    </div>
  </div>
  </div>
  <br /><br />

    </div>
  )
}
export default Faq;