import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import Home from '../pages/Home';
import About from '../pages/About';
import Faq from '../pages/Faq';
import Contact from '../pages/Contact';
import Blog from '../pages/Blog';
import Privacy from '../pages/Privacy';
import Features from '../pages/Features';
import Testimonial from '../pages/Testimonal';
import Trade from '../pages/Trade';
import Discord from '../pages/Discord';
import Blg1 from '../pages/Blg1';
import Blg2 from '../pages/Blg2';
import Blg3 from '../pages/Blg3';
import Blg4 from '../pages/Blg4';
import Blg5 from '../pages/Blg5';
import Blg6 from '../pages/Blg6';
import Blg7 from '../pages/Blg7';
import Blg8 from '../pages/Blg8';
import Blg9 from '../pages/Blg9';
import Blg10 from '../pages/Blg10';
import Blg11 from '../pages/Blg11';
import Blg12 from '../pages/Blg12';
const Routers =()=> {
  return (
    <Routes>
    <Route path='/' element={<Navigate to='/Home'/>} />
      <Route path='home' element={<Home/>} />
      <Route path='about' element={<About/>} />
      <Route path='Faq' element={<Faq/>} />
      <Route path='Contact' element={<Contact/>} />
      <Route path='Blog' element={<Blog/>} />
      <Route path='Privacy' element={<Privacy/>} />
      <Route path='Features' element={<Features/>} />
      <Route path='Testimonial' element={<Testimonial/>} />
      <Route path='Trade' element={<Trade/>} />
      <Route path='TradeSignal' element={<Discord/>} />
      <Route path='Understanding-Technical-Indicators' element={<Blg1/>} />
      <Route path='How-to-Build-a-Winning-Stock-Trading-Plan' element={<Blg2/>} />
      <Route path='What-Are-Trading-Signals-and-How-Can-They-Help-You' element={<Blg3/>} />
      <Route path='The-Basics-of-Stock-Trading' element={<Blg4/>} />
      <Route path='Is-Investing-in-Stocks-Really-Worth-It-in-2025' element={<Blg5/>} />
      <Route path='What-Are-Stocks-and-How-Do-Stocks-Make-Money' element={<Blg6/>} />
      <Route path='Starting-Your-Stock-Trading-Journey' element={<Blg7/>} />
      <Route path='Unprecedented-Market-Trends' element={<Blg8/>} />
      <Route path='5-Common-Mistakes-Beginner-Traders-Make-and-How-to-Avoid-Them' element={<Blg9/>} />
      <Route path='How-to-Trade-Smarter-with-My-Trade-Signal' element={<Blg10/>} />
      <Route path='The-Power-of-Real-Time-Trading-Signals' element={<Blg12/>} />
      <Route path='#' element={<Blg11/>} />
          </Routes>
    
  )
}
export default Routers;