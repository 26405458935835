import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import {Helmet}from"react-helmet";
import t1 from '../images/tr2.png';
import ab1 from '../images/Group 7284-min.png';
import ab2 from '../images/Group 7285-min.png';
import ab3 from '../images/Frame 3572-min.png';
import bit from '../images/bitcoin_btc_0003-Recovered 1-min.png';

import AOS from 'aos';
import signn from '../images/signn.png';
import 'aos/dist/aos.css';

 const Contact =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Contact Us | MyTradeSignal Support & Inquiries</title>
      <meta name="description" content="Get in touch with MyTradeSignal for support and inquiries. Our team is here to assist you with any questions or issues. Reach out today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
     <div className='main__t'>
     <div className='row_t'>

        <div className='t_col'>
          <img src={t1}></img>
        </div>
        <div className='t_col'>
          <h1>Contact Us </h1>
          <p>Reach out to us—your success is our priority. </p>
          <br />
          <button style={{color:"black"}}>Let’s Start A Trial <img style={{width:"10%"}} src={signn} alt="" /> </button>
        </div>
     </div>
     </div>
    </section>
  
<section>
<div className='contact__section'>
 <div className='contact__row'>
  <div className='contact__col1'>
    <h2>Contact Us</h2>
    <br />
    <div className='form__inp__row'>
     <div className='form__col__inp'>
      <input type="Name" value="NAME"  />
     </div>
     <div className='form__col__inp'>
     <input type="Name" value="Email" />
     </div> 
    </div>
    <br />
    <div className='form__inp__row'>
     <div className='form__col__inp'>
      <input type="Name"  value="Phone Number" />
     </div>
     <div className='form__col__inp'>
     <input type="Name"  value="Subject"/>
     </div> 
    </div>
<br />
     <input type="text" value="Write Message" style={{height:"30vh"}}/>
     <br />
     <br />
     <button id='bttn'>Submit</button>
     <br />
     <br />
  </div>
  <div className='contact__col2'>
  <h1>Contact Us </h1>
          <p>Reach out to us—your success is our priority. </p>
       
     <br />

     <h3> <i class="ri-mail-fill"></i>
     &nbsp; <a href="mailto: info@mytradesignal.com" >&nbsp;&nbsp; info@mytradesignal.com</a></h3>
 
  </div>
 </div>
</div>
</section>


<br /><br />
<section style={{width:"80%",margin:"auto"}} >
          <iframe title='map'style={{height:"60vh",width:"100%"}} className='mapcon' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.3943005276515!2d-95.46552238254637!3d29.73729969083219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16c2cf13989%3A0x9fd2bbab78247d62!2s2800%20Post%20Oak%20Blvd%2C%20Houston%2C%20TX%2077056%2C%20USA!5e0!3m2!1sen!2s!4v1675405427489!5m2!1sen!2s"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          
          </section>
<br />
<div className='div__9a'>
  <div className='div__9__row'>
    <div className='div__9a__col'>
      <img src={bit}></img>
    </div>
    <div className='div__9a__col'>
      <h2>Subscribe our news</h2>
    <p>
    Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</p>
    <input type="Name" style={{padding:"10px"}} value="Enter your email" />
    <button style={{color:"black",fontFamily:"Nunito",padding:"10px",border:"1px solid white"}}>Submit</button> 
    </div>
  </div>
  </div>
  <br /><br />

    </div>
  )
}
export default Contact;