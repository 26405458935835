import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/blog.css'
import {Helmet}from"react-helmet";
import t1 from '../images/tr2.png';
import signn from '../images/signn.png';
import bit from '../images/bitcoin_btc_0003-Recovered 1-min.png';
import bg1 from '../images/f_img.png';
import bg2 from '../images/blg3a.png';
import bg4 from '../images/blg4.png';
import bg5 from '../images/blg5a.png';
import bg6 from '../images/blg6a-min.png';
import bg7 from '../images/blg7a-min.png';
import bg8 from '../images/blg8a-min.png';
import bg9 from '../images/blg9a-min.png';
import bg10 from '../images/blg11a-min.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Blog =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Latest Stock Market Insights & Trading Tips | MyTrade Signal</title>
      <meta name="description" content="Discover the latest stock market insights and expert trading tips with MyTrade Signal. Stay ahead with our up-to-date analysis and actionable strategies."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
     <div className='main__t'>
     <div className='row_t'>

        <div className='t_col'>
          <img src={t1}></img>
        </div>
        <div className='t_col'>
          <h1>Blog </h1>
          <p>Explore everything you need to know about trading—how to trade, why trade, tips, guides, and insights into different trading styles and strategies, all in one place.</p>
          <br />
          <button style={{color:"black"}}>Let’s Start A Trial <img style={{width:"10%"}} src={signn} alt="" /> </button>
        </div>
     </div>
     </div>
    </section>
    
<section>
<div className='blog__seca'>
 <div className='blog_row'>
  <div className='blog__col'>
    <a href="Understanding-Technical-Indicators">    <img src={bg1} alt="" /></a>
<br /><br />
    <h2>
      <a href="Understanding-Technical-Indicators">Understanding Technical Indicators for Better Trades
    </a> </h2>
  </div>
  <div className='blog__col'>
    <a href="How-to-Build-a-Winning-Stock-Trading-Plan">    <img src={bg1} alt="" /></a>
    <br /><br />
    <h2><a href="How-to-Build-a-Winning-Stock-Trading-Plan">How to Build a Winning Stock Trading Plan
    </a> </h2>

  </div>
  
  <div className='blog__col'>
    <a href="What-Are-Trading-Signals-and-How-Can-They-Help-You">    <img src={bg2} alt="" /></a>
    <br /><br />
    <h2><a href="What-Are-Trading-Signals-and-How-Can-They-Help-You">What Are Trading Signals and How Can They Help You?
    </a> </h2>

  </div>
   </div>
 <br /><br />
 <div className='blog_row'>
  <div className='blog__col'>
    <a href="The-Basics-of-Stock-Trading">    <img src={bg4} alt="" /></a>
<br /><br />
    <h2>
      <a href="The-Basics-of-Stock-Trading">The Basics of Stock Trading
    </a> </h2>
  </div>
  <div className='blog__col'>
    <a href="Is-Investing-in-Stocks-Really-Worth-It-in-2025">    <img src={bg5} alt="" /></a>
<br /><br />
    <h2>
      <a href="Is-Investing-in-Stocks-Really-Worth-It-in-2025">Is Investing in Stocks Really Worth It in 2025
    </a> </h2>
  </div>
  <div style={{}} className='blog__col'>
    <a href="What-Are-Stocks-and-How-Do-Stocks-Make-Money">    <img src={bg6} alt="" /></a>
<br /><br />
    <h2>
      <a href="What-Are-Stocks-and-How-Do-Stocks-Make-Money">What Are Stocks and How Do Stocks Make-Money
    </a> </h2>
  </div>
   </div>
   <br /><br />
 <div className='blog_row'>
  <div className='blog__col'>
    <a href="Starting-Your-Stock-Trading-Journey">    <img src={bg7} alt="" /></a>
<br /><br />
    <h2>
      <a href="Starting-Your-Stock-Trading-Journey">Starting Your Stock Trading Journey
    </a> </h2>
  </div>
  <div  className='blog__col'>
    <a href="Unprecedented-Market-Trends">    <img src={bg8} alt="" /></a>
<br /><br />
    <h2>
      <a href="Unprecedented-Market-Trends">Unprecedented Market Trends: What Investors Need to Know
    </a> </h2>
  </div>
  <div  className='blog__col'>
    <a href="5-Common-Mistakes-Beginner-Traders-Make-and-How-to-Avoid-Them">    <img src={bg9} alt="" /></a>
<br /><br />
    <h2>
      <a href="5-Common-Mistakes-Beginner-Traders-Make-and-How-to-Avoid-Them">5 Common Mistakes Beginner Traders Make and How to Avoid Them
    </a> </h2>
  </div>
   </div>
   <br /><br />
 <div className='blog_row'>
  <div className='blog__col'>
    <a href="How-to-Trade-Smarter-with-My-Trade-Signal">    <img src={bg10} alt="" /></a>
<br /><br />
    <h2>
      <a href="How-to-Trade-Smarter-with-My-Trade-Signal">How to Trade Smarter with My Trade Signal
    </a> </h2>
  </div>

   </div>
</div>

</section>


<br /><br />
<div className='div__9a'>
  <div className='div__9__row'>
    <div className='div__9a__col'>
      <img src={bit}></img>
    </div>
    <div className='div__9a__col'>
      <h2><span style={{color:"#0A4FD5"}}>Subscribe</span>  Our News</h2>
    <p>
    Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</p>
    <br />
    <input type="Name" style={{padding:"10px"}} value="Email Address" />
    <button style={{color:"black",fontFamily:"Nunito",color:"white", padding:"10px",border:"1px solid white"}}>Submit</button> 
    </div>
  </div>
  </div>
  <br /><br />

    </div>
  )
}
export default Blog;